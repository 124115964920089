
  .header2 {
    background-color: #4CAF50;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    outline: none; 
  }
  .SectionHomepage{

    padding: 20px;
    text-align: left;
    color: black;
    border-radius: 8px;
  }