body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    background-color: #f4f4f4;
  }
  
  .App-header {
    background-color: #0066cc;
    padding: 20px;
    color: white;
    text-align: center;
  }
  
  .section {
    padding: 20px;
    margin: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section h2 {
    color: #0066cc;
    margin-bottom: 15px;
  }
  
  .section ul {
    list-style-type: square;
    padding-left: 20px;
  }
  
  .section p,
  .section ul {
    font-size: 1.1rem;
    color: #333;
  }
  