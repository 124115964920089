/* src/components/ContactUs.css */
.contact-info {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .Contact h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .Contact form {
    display: flex;
    flex-direction: column;
  }
  
  .Contactlabel {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .Contact input,
  .Contact textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 0.5rem;
  }
  
  .Contact textarea {
    resize: vertical;
  }

  .contact-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: left;
  }
  
  .contact-section h2 {
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  
  .Contact button {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Contact button:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    margin-top: 2rem;
    text-align: center;
  }
  
  .contact-info h3 {
    margin-bottom: 1rem;
  }
  
  .contact-info p {
    margin: 0.5rem 0;
  }
  