/* src/components/LogosDropdown.css */
.logos-dropdown {
    position: relative;
    display: inline-block;
    margin: 0px;
  }
  
  .dropdown-button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .dropdown-button:hover {
    background-color: #2980b9;
  }
  
  .dropdown-content {
    display: flex;
    flex-wrap: wrap;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50px; /* Ajustez selon vos besoins */
    left: 0;
    z-index: 1;
    max-height: 300px; /* Limite la hauteur */
    overflow-y: auto; /* Ajoute un scroll si nécessaire */
    transition: max-height 0.3s ease-in-out;
  }
  
  .logo-item {
    width: 100px; /* Ajustez la taille des logos */
    margin: 10px;
    transition: transform 0.3s;
  }
  
  .logo-item:hover {
    transform: scale(1.1); /* Zoom léger au survol */
  }
  