/* src/components/PartnersLogos.css */
.partners-logos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: left;
    align-items: center;
    padding: 10px;
  }
  
  .partner-logo {
    width: 100px; /* Ajustez la taille selon les besoins */
    height: auto;
    object-fit: contain; /* Pour préserver le ratio des logos */
    transition: transform 0.3s;
  }
  
  .partner-logo:hover {
    transform: scale(1.2); /* Animation au survol */
  }
  