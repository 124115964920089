.alpd-Ressources {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #070101;
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  
  h1, h2 {
    color: #007efb;
    font-size: 24px;
    margin-bottom: 10px;
    border-bottom: 2px solid #7c7474;
    padding-bottom: 5px;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  