/* src/components/Header.css */

header {
    background-color: #343a40;
    padding: 10px;
    color: white;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: left;
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: aliceblue;
  }
  .navbar-toggler-icon {
    background-color: #ffffff; /* Couleur de l'icône du bouton */
  }
  .navbar-toggler {
    font-size: 1.5rem;
    background-color: white;
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: none;
    align-items: right;
  }
  
  .navbar-menu {
    display: flex;
    flex-direction: row;
  }
  
  .navbar-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 15px;
  }
  
  .navbar-menu ul li a {
    color: white;
    text-decoration: none;
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  .navbar-menu ul li a.active,
  .navbar-menu ul li a:hover {
    background-color: #656464;
    border-radius: 5px;
  }
  
  /* Responsive Menu for Small Screens */
  @media (max-width: 768px) {
    .navbar-toggler {
      display: block;
    }
  
    .navbar-menu {
      flex-direction: column;
      display: none;
    }
  
    .navbar-menu.is-active {
      display: flex;
    }
  
    .navbar-menu ul {
      flex-direction: column;
      width: 100%;
    }
  
    .navbar-menu ul li {
      text-align: center;
    }
  }
  