.container {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 1200px;
    padding: 20px;
  }
  
  .section {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .section-specialle {

    padding: 20px;
    margin-bottom: 20px;
    background-color: #bba1a1;
    border-radius: 8px;
    color: rgb(248, 247, 246);
    box-shadow: 0 2px 8px rgb(248, 248, 248);

  }
  
  .title {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
    border-bottom: 2px solid #0066cc;
    padding-bottom: 5px;
  }
  
  .subtitle {
    color: #0066cc;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .subtitle-specialle{
    color: #000000;
    font-size: 20px;
    margin-bottom: 10px;

  }
  
  .text {
    color: #555;
    font-size: 16px;
    line-height: 1.6;
  }

  .text-specialle{

    color: #fff9f9;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  
  .listItem {
    margin-bottom: 5px;
  }
  